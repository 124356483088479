<template>
    <div>
        <section class="relative block py-20 overflow-hidden leading-6 text-left bg-white bg-indigo-900">
            <div class="w-full max-w-5xl px-8 mx-auto leading-6 text-left xl:px-12">
                <div
                    class="relative w-full px-4 leading-6 text-center xl:flex-grow-0 xl:flex-shrink-0 lg:flex-grow-0 lg:flex-shrink-0">
                    <div class="box-border text-2xl font-semibold text-indigo-300 uppercase">
                        Privacy Policy
                    </div>
                    <p class="box-border mx-0 mt-6 mb-0 text-sm font-normal leading-normal text-indigo-100 sm:text-lg">
                        Last updated: <i>May 19, 2021</i><br/>
                    </p>
                </div>
            </div>
        </section>

        <!-- Section 2 -->
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        At tokengen.net, accessible from tokengen.net, one of our main priorities is the privacy of our
                        visitors. This Privacy Policy document contains types of information that is collected and
                        recorded by tokengen.net and how we use it.

                        If you have additional questions or require more information about our Privacy Policy, do not
                        hesitate to contact us.
                    </p>
                </div>

            </div>
        </section>

        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">Consent</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                    </p>
                </div>

            </div>
        </section>

        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">Information we collect</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        The personal information that you are asked to provide, and the reasons why you are asked to
                        provide it, will be made clear to you at the point we ask you to provide your personal
                        information.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        If you contact us directly, we may receive additional information about you such as your name,
                        email address, phone number, the contents of the message and/or attachments you may send us, and
                        any other information you may choose to provide.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        When you register for an Account, we may ask for your contact information, including items such
                        as name, company name, address, email address, and telephone number.
                    </p>
                </div>

            </div>
        </section>

        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">How we use your information</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <div class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        We use the information we collect in various ways, including to:
                        <ul class="list-disc">
                            <li>Provide, operate, and maintain our website</li>
                            <li>Improve, personalize, and expand our website</li>
                            <li>Understand and analyze how you use our website</li>
                            <li>Develop new products, services, features, and functionality</li>
                            <li>Communicate with you, either directly or through one of our partners, including for
                                customer
                                service, to provide you with updates and other information relating to the website, and
                                for
                                marketing and promotional purposes
                            </li>
                            <li>Send you emails</li>
                            <li>Find and prevent fraud</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>

        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">Log Files</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        tokengen.net follows a standard procedure of using log files. These files log visitors when they
                        visit websites. All hosting companies do this and a part of hosting services' analytics. The
                        information collected by log files include internet protocol (IP) addresses, browser type,
                        Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
                        number of clicks. These are not linked to any information that is personally identifiable. The
                        purpose of the information is for analyzing trends, administering the site, tracking users'
                        movement on the website, and gathering demographic information.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">Cookies and Web Beacons</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Like any other website, tokengen.net uses 'cookies'. These cookies are used to store information
                        including visitors' preferences, and the pages on the website that the visitor accessed or
                        visited. The information is used to optimize the users' experience by customizing our web page
                        content based on visitors' browser type and/or other information.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        For more general information on cookies, please read <a
                        href="https://www.privacypolicyonline.com/what-are-cookies/" class="underline">"What Are
                        Cookies" from Cookie Consent</a>.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">Advertising Partners Privacy Policies</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        You may consult this list to find the Privacy Policy for each of the advertising partners of
                        tokengen.net.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons
                        that are used in their respective advertisements and links that appear on tokengen.net, which
                        are sent directly to users' browser. They automatically receive your IP address when this
                        occurs. These technologies are used to measure the effectiveness of their advertising campaigns
                        and/or to personalize the advertising content that you see on websites that you visit.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        Note that tokengen.net has no access to or control over these cookies that are used by
                        third-party advertisers.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">Third Party Privacy Policies</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        tokengen.net's Privacy Policy does not apply to other advertisers or websites. Thus, we are
                        advising you to consult the respective Privacy Policies of these third-party ad servers for more
                        detailed information. It may include their practices and instructions about how to opt-out of
                        certain options.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        You can choose to disable cookies through your individual browser options. To know more detailed
                        information about cookie management with specific web browsers, it can be found at the browsers'
                        respective websites.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">CCPA Privacy Rights (Do Not Sell My Personal
                        Information)</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Under the CCPA, among other rights, California consumers have the right to:
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        Request that a business that collects a consumer's personal data disclose the categories and
                        specific pieces of personal data that a business has collected about consumers.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        Request that a business delete any personal data about the consumer that a business has
                        collected.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        Request that a business that sells a consumer's personal data, not sell the consumer's personal
                        data.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">GDPR Data Protection Rights</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        We would like to make sure you are fully aware of all of your data protection rights. Every user
                        is entitled to the following:
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        The right to access – You have the right to request copies of your personal data. We may charge
                        you a small fee for this service.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        The right to rectification – You have the right to request that we correct any information you
                        believe is inaccurate. You also have the right to request that we complete the information you
                        believe is incomplete.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        The right to erasure – You have the right to request that we erase your personal data, under
                        certain conditions.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        The right to restrict processing – You have the right to request that we restrict the processing
                        of your personal data, under certain conditions.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        The right to object to processing – You have the right to object to our processing of your
                        personal data, under certain conditions.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        The right to data portability – You have the right to request that we transfer the data that we
                        have collected to another organization, or directly to you, under certain conditions.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">Children's Information</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                    </p>
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg mt-4">
                        tokengen.net does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </p>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "privacy-policy"
}
</script>

<style scoped>

</style>
